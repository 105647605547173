import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import isArray from 'lodash/isArray';
import { Typography } from 'antd';

const { Title } = Typography;

const PageHeader = function pageHeader({
  photo, title,
}) {
  const mainPhoto = isArray(photo) && photo.length ? photo[0] : photo;
  return (
    <div className='page-header'>
      <div
        className='page-header__image'
        style={{ backgroundImage: `url(${ get(mainPhoto, 'url') })` }}
      />
      <div className='page-header__wrapper'>
        <Title
          level={3}
          className='page-header__wrapper--title'
        >
          {title}
        </Title>
      </div>
    </div>
  );
};

PageHeader.propTypes = {
  photo: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
  title: PropTypes.string.isRequired,
};

export default PageHeader;
