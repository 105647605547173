import React from 'react';
import PropTypes from 'prop-types';
import map from 'lodash/map';
import {
  Row, Col, Typography, Space,
} from 'antd';
import { contact } from 'src/helpers/contact';

const { Link } = Typography;

const PageFooter = function ({ socialNavigationItems }) {
  const year = new Date().getFullYear();
  return (
    <Row justify='center'>
      <Col
        xs={24}
        md={8}
      >
        {`© ${ year } Małgorzata Rocławska`}
      </Col>

      <Col
        xs={24}
        md={8}
      >
        {contact.mail}
      </Col>

      <Col
        xs={24}
        md={8}
      >
        <Space>
          {map(socialNavigationItems, (item) => (
            <Link
              key={item.location}
              href={item.location}
              target='_blank'
            >
              {item.name}
            </Link>
          ))}
        </Space>
      </Col>
    </Row>
  );
};

PageFooter.propTypes = {
  socialNavigationItems: PropTypes.array.isRequired,
};

export default PageFooter;
