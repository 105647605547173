import React from 'react';
import { FacebookOutlined, InstagramOutlined, YoutubeOutlined } from '@ant-design/icons';

export const navigationItems = Object.freeze([
  {
    label: 'HOME',
    link: '/',
  },
  {
    label: 'ABOUT',
    link: '/about',
  },
  {
    label: 'SCHEDULE',
    link: '/schedule',
  },
  {
    label: 'REPERTOIRE',
    link: '/repertoire',
  },
  {
    label: 'REVIEWS',
    link: '/reviews',
  },
  {
    label: 'GALLERY',
    link: '/gallery',
  },
  {
    label: 'CONTACT',
    link: '/contact',
  },
]);

export const socialNavigationItems = Object.freeze([
  {
    location: 'https://www.facebook.com/profile.php?id=100001909534016',
    name: 'facebook',
    icon: <FacebookOutlined />,
    alt: 'Facebook icon',
  },
  {
    location: 'https://www.instagram.com/m.roclawska_sopran/',
    name: 'instagram',
    icon: <InstagramOutlined />,
    alt: 'Instagram icon',
  },
  {
    location: 'https://www.youtube.com/results?search_query=ma%C5%82gorzata+roc%C5%82awska',
    name: 'youtube',
    icon: <YoutubeOutlined />,
    alt: 'Youtube icon',
  },
]);
