import { useEffect, useState } from 'react';

function useContentful(query) {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    setLoading(true);
    window.fetch(
      `https://graphql.contentful.com/content/v1/spaces/${ process.env.GATSBY_CONTENTFUL_SPACE_ID }`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${ process.env.GATSBY_CONTENTFUL_ACCESS_TOKEN }`,
        },
        body: JSON.stringify({ query }),
      },
    )
      .then((response) => response.json())
      .then(({ data: respData, error: respError }) => {
        if (respError) setError(respError);
        if (respData) setData(respData);
        setLoading(false);
      })
      .catch(() => {
        setError(true);
        setLoading(false);
      });
  }, [query, setError, setLoading]);

  return {
    data,
    loading,
    error,
  };
}

export default useContentful;
