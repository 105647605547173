import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import map from 'lodash/map';
import { Grid, Menu } from 'antd';
import { Link } from 'gatsby';
import { navigationItems } from './navigationItems';
import NavigationMobile from './NavigationMobile';
import NavigationDesktop from './NavigationDesktop';

const { useBreakpoint } = Grid;

const Navigation = function ({ location }) {
  const { lg } = useBreakpoint();
  const { t } = useTranslation();
  const menuItems = useMemo(() => (map(navigationItems, (item) => (
    <Menu.Item key={item.link}>
      <Link
        to={item.link}
        activeClassName='active'
        className='mobile-menu--link'
      >
        {t(item.label)}
      </Link>
    </Menu.Item>
  ))), []);

  return (
    !lg ? (
      <NavigationMobile menuItems={menuItems} />
    ) : (
      <NavigationDesktop
        menuItems={menuItems}
        location={location}
      />
    )
  );
};

Navigation.propTypes = {
  location: PropTypes.object.isRequired,
};

export default Navigation;
