/* eslint-disable react/no-invalid-html-attribute */
import * as React from 'react';
import { Helmet } from 'react-helmet';
import { withPrefix, graphql, useStaticQuery } from 'gatsby';

const Seo = function () {
  const { site } = useStaticQuery(graphql`
      query SITE_METADATA_QUERY {
          site {
              siteMetadata {
                  title
                  description
              }
          }
      }
  `);
  const { title, description } = site.siteMetadata;
  return (
    <Helmet>
      <html lang='en' />
      <title>{title}</title>
      <meta
        name='description'
        content={description}
      />
      <link
        rel='apple-touch-icon'
        sizes='180x180'
        href={`${ withPrefix('/') }favicon/apple-touch-icon.png`}
      />
      <link
        rel='icon'
        type='image/png'
        sizes='32x32'
        href={`${ withPrefix('/') }favicon/favicon-32x32.png`}
      />
      <link
        rel='icon'
        type='image/png'
        sizes='16x16'
        href={`${ withPrefix('/') }favicon/favicon-16x16.png`}
      />
      <link
        rel='manifest'
        href={`${ withPrefix('/') }favicon/site.webmanifest`}
      />

      <meta
        name='theme-color'
        content='#fff'
      />

      <meta
        property='og:type'
        content='business.business'
      />
      <meta
        property='og:title'
        content={title}
      />
      <meta
        property='og:url'
        content='/'
      />
      <meta
        property='og:image'
        content={`${ withPrefix('/') }img/kamil-dominiak004.jpg`}
      />
    </Helmet>
  );
};

export default Seo;
