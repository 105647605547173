import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import translationsPl from '../locales/pl/translations';
import translationsEn from '../locales/en/translations';

i18n
  .use(initReactI18next)
  .init({
    fallbackLng: 'en',
    resources: {
      pl: {
        translations: translationsPl,
      },
      en: {
        translations: translationsEn,
      },
    },
    ns: ['translations'],
    defaultNS: 'translations',
    returnObjects: true,
    debug: process.env.NODE_ENV === 'development',
    react: {
      wait: true,
    },
  });

i18n.languages = ['pl', 'en'];

export default i18n;
