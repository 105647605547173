import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Menu } from 'antd';
import { useTrail, a } from '@react-spring/web';
import Hamburger from './Hamburger';

// eslint-disable-next-line func-names
const Trail = function ({ open, children }) {
  const items = React.Children.toArray(children);
  const trail = useTrail(items.length, {
    config: { mass: 5, tension: 2000, friction: 200 },
    opacity: open ? 1 : 0,
    x: open ? 0 : 20,
    height: open ? 40 : 0,
    from: { opacity: 0, x: 20, height: 0 },
  });

  return (
    <div>
      {trail.map(({ height, ...style }, index) => (
        <a.div
          key={`menu-mobile-${ index + 1 }`}
          // className={styles.trailsText}
          style={style}
        >
          <a.div style={{ height }}>{items[index]}</a.div>
        </a.div>
      ))}
    </div>
  );
};

Trail.propTypes = {
  children: PropTypes.node.isRequired,
  open: PropTypes.bool.isRequired,
};

// eslint-disable-next-line react/function-component-definition
export default function NavigationMobile({ menuItems }) {
  const [open, setOpen] = useState(false);

  const toggleNavigation = useCallback(() => {
    setOpen((prevOpen) => !prevOpen);
  }, []);

  return (
    <>
      <Hamburger
        isOpen={open}
        setOpen={toggleNavigation}
      />
      {open && (
        <div className='mobile-menu test'>
          <Menu selectable={false}>
            {menuItems}
          </Menu>
        </div>
      )}
    </>
  );
}

NavigationMobile.propTypes = {
  menuItems: PropTypes.array.isRequired,
};
