import 'src/styles/less/all.less';
import 'src/styles/all.scss';
import 'rc-texty/assets/index.css';
import React from 'react';
import PropTypes from 'prop-types';
import 'src/i18n/config';
import map from 'lodash/map';
import { Link } from 'gatsby';
import {
  Grid,
  Layout as AntLayout, Spin,
} from 'antd';
import { socialNavigationItems } from 'src/components/navigation/navigationItems';
import Seo from './seo';
import Navigation from './navigation/Navigation';
import PageFooter from './PageFooter';
import PageHeader from './PageHeader';
import LogoDark from '../../static/images/logo_dark.svg';
import Spinner from './Spinner';

const { Header, Content, Footer } = AntLayout;
const { useBreakpoint } = Grid;

const PageLayout = function ({
  children, isHomepage, loading, location,
  photo, subTitle, title, layoutClassName, mainStyles,
}) {
  const { lg } = useBreakpoint();
  return (
    <>
      <Seo title={title} />
      {loading ? (
        <div className='loader-container'>
          <Spin size='large' />
        </div>
      ) : (
        <div>
          <Spinner />
          <Header className='header'>
            <Link to='/'>
              <div className='header__identity'>
                <img
                  src={LogoDark}
                  alt='Małgorzata Rocławska Logo'
                  className='header__identity--logo'
                />
              </div>
            </Link>
            <Navigation location={location} />
          </Header>
          <AntLayout className={`site-layout ${ layoutClassName }`}>
            {!isHomepage && !lg && (
              <PageHeader
                photo={photo}
                title={title}
                subTitle={subTitle}
              />
            )}

            <Content
              className='site-layout__content'
              style={mainStyles}
            >
              {children}
            </Content>

            <div className='social-media-vertical-nav'>
              {map(socialNavigationItems, (item) => (
                <Link
                  key={item.location}
                  href={item.location}
                  target='_blank'
                >
                  {item.name}
                </Link>
              ))}
            </div>
          </AntLayout>
          <Footer className='footer'>
            <PageFooter socialNavigationItems={socialNavigationItems} />
          </Footer>
        </div>
      )}
    </>
  );
};

PageLayout.defaultProps = {
  isHomepage: false,
  layoutClassName: '',
  mainStyles: {},
  photo: [],
  title: '',
  subTitle: '',
};

PageLayout.propTypes = {
  children: PropTypes.node.isRequired,
  loading: PropTypes.bool.isRequired,
  location: PropTypes.object.isRequired,
  isHomepage: PropTypes.bool,
  layoutClassName: PropTypes.string,
  mainStyles: PropTypes.object,
  photo: PropTypes.array,
  subTitle: PropTypes.string,
  title: PropTypes.string,
};

export default PageLayout;
