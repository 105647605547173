import React from 'react';
import Texty from 'rc-texty';
import 'rc-texty/assets/index.css';
import TweenOne from 'rc-tween-one';

const Spinner = function () {
  const getSplit = (e) => {
    const t = e.split(' ');
    const c = [];
    t.forEach((str, i) => {
      c.push((
        <span key={`${ str }-${ i + 1 }`}>
          {str}
        </span>
      ));
      if (i < t.length - 1) {
        c.push(<span key={` -${ i + 1 }`}> </span>);
      }
    });
    return c;
  };

  return (
    <div className='combined-wrapper'>
      <div className='combined'>
        <Texty
          className='title'
          type='mask-top'
          delay={0}
          component={TweenOne}
        >
          Małgorzata Rocławska
        </Texty>

        <Texty
          className='content'
          type='bottom'
          split={getSplit}
          delay={800}
          interval={30}
        >
          soprano
        </Texty>
      </div>
    </div>
  );
};

export default Spinner;
