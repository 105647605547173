import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import trimEnd from 'lodash/trimEnd';
import { Menu } from 'antd';

// eslint-disable-next-line react/function-component-definition
export default function NavigationDesktop({ menuItems, location }) {
  const initialSelected = useMemo(() => (location.pathname === '/' ? location.pathname : trimEnd(location.pathname, '/')), [location.pathname]);
  const [currentSelected, setCurrentSelected] = useState(initialSelected);
  const handleSetCurrentSelected = useCallback((selected) => {
    setCurrentSelected(selected.key);
  }, []);

  return (
    <div className='navigation navigation-desktop'>
      <Menu
        mode='horizontal'
        selectedKeys={[currentSelected]}
        onSelect={handleSetCurrentSelected}
      >
        {menuItems}
      </Menu>
    </div>
  );
}

NavigationDesktop.propTypes = {
  location: PropTypes.object.isRequired,
  menuItems: PropTypes.array.isRequired,
};
